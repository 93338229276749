import type { Row as RowType } from "@tanstack/table-core";
import Cell from "./cell";

interface RowProps<T> {
  row: RowType<T>;
}

const Row = <T,>({ row }: RowProps<T>) => {
  return (
    <tr className="bg-background text-content/80 font-code border">
      {row.getVisibleCells().map((cell) => (
        <Cell key={cell.id} cell={cell} />
      ))}
    </tr>
  );
};

export default Row;
