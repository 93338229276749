import clsx from "clsx";

import { type Header as HeaderType, flexRender } from "@tanstack/react-table";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";
interface HeaderProps<T, V> {
  header: HeaderType<T, V>;
}

const Header = <T, V>({ header }: HeaderProps<T, V>) => {
  const canSort = header.column.getCanSort();
  const isLast = header.index === header.getContext().table.getAllColumns().length - 1;
  const isSticky = header.column.getIsPinned();
  const headerClasses = clsx("border-x relative group p-2", {
    "cursor-pointer": canSort,
    "w-full": isLast,
    "sticky left-0 z-10": isSticky,
  });
  const isSorted = header.column.getIsSorted();
  const handleSort = header.column.getToggleSortingHandler();
  return (
    <th key={header.id} className={headerClasses} colSpan={header.colSpan}>
      <div className="flex" onClick={handleSort}>
        <div className="overflow-hidden truncate whitespace-nowrap text-sm font-medium">
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
        </div>
        <div>
          {isSorted ? (
            isSorted === "asc" ? (
              <ChevronUpIcon className="size-4" />
            ) : (
              <ChevronDownIcon className="size-4" />
            )
          ) : null}
        </div>
      </div>
    </th>
  );
};

export default Header;
