import { Input } from "@fscrypto/ui";
import { memo } from "react";

const FooterSearchComponent = ({
  globalFilter,
  setGlobalFilter,
}: {
  globalFilter?: string;
  setGlobalFilter?: (val: string) => void;
}) => {
  return (
    <Input
      type="text"
      size="sm"
      placeholder="Search..."
      value={globalFilter}
      onChange={(e) => setGlobalFilter?.(e.target.value)}
    />
  );
};

export const FooterSearch = memo(FooterSearchComponent);
