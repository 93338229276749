import type { Table } from "@tanstack/react-table";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";

interface PaginationProps<T> {
  table: Table<T>;
}

export const PaginationControls = <T,>({ table }: PaginationProps<T>) => {
  return (
    <div className="text-gray-60 dark:text-gray-30 flex items-center gap-x-2 p-2 text-xs">
      <button
        className="text-gray-80 dark:text-gray:30 dark:disabled:text-gray-40 flex items-center disabled:text-gray-50"
        onClick={() => table.getCanPreviousPage() && table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeftIcon className="text-content size-4" />
        <span className="dark:text-gray-30 hidden md:block">Prev</span>
      </button>
      <div className="text-center lg:min-w-[80px]">
        Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
      </div>
      <button
        className="text-gray-80 dark:text-gray-30 dark:disabled:text-gray-40 flex  items-center disabled:text-gray-50"
        onClick={() => table.getCanNextPage() && table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <span className="text-content hidden md:block">Next</span>
        <ChevronRightIcon className="text-content size-4" />
      </button>
    </div>
  );
};
