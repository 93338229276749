import {
  useReactTable,
  getCoreRowModel,
  type ColumnDef,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  OnChangeFn,
  getFilteredRowModel,
  FilterFn,
  Table as TableState,
} from "@tanstack/react-table";

import HeaderRow from "./components/header-row";
import { PaginationControls } from "./components/pagination";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import Row from "./components/row";
import { FooterSearch } from "./components/footer-search";
import { AlertTriangleIcon } from "lucide-react";

interface TableProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<T, any>[];
  data: T[];
  paginate?: boolean;
  initialPageSize?: number;
  /** Optional header info to display above the table.  Useful for table names and results stats */
  headerInfo?: (() => React.ReactNode) | React.ReactNode;
  dashboardView?: boolean;
  previewView?: boolean;
  transpose?: boolean;
  onSortingChange?: OnChangeFn<SortingState>;
  sorting?: SortingState;
  tableId: string;
  filter?: string;
  onFilterChange?: (value: string) => void;
  pageIndex?: number; // Controlled externally
  onPageIndexChange?: (newPage: number) => void; // Callback to update the current page externally
  customFooter?: (table: TableState<T>) => React.ReactNode;
}
export const Table = <T,>({
  columns,
  data,
  initialPageSize,
  paginate,
  headerInfo,
  dashboardView,
  previewView,
  transpose,
  onSortingChange,
  sorting,
  tableId,
  filter: externalGlobalFilter,
  onFilterChange: externalSetGlobalFilter,
  pageIndex,
  onPageIndexChange,
  customFooter,
}: TableProps<T>) => {
  const tableWrapperStyles = clsx("scrollbar border-y w-full flex-1 text-content/80");
  const [internalGlobalFilter, setInternalGlobalFilter] = useState("");

  // Determine whether to use internal state or external props for global filter
  const isGlobalFilterControlled = externalGlobalFilter !== undefined && externalSetGlobalFilter !== undefined;
  const globalFilter = isGlobalFilterControlled ? externalGlobalFilter : internalGlobalFilter;
  const setGlobalFilter = isGlobalFilterControlled ? externalSetGlobalFilter : setInternalGlobalFilter;

  useEffect(() => {
    if (!isGlobalFilterControlled) {
      setInternalGlobalFilter("");
    }
  }, [tableId, isGlobalFilterControlled]);

  const stringFilter = useCallback<FilterFn<T>>((row, columnId, filterVal) => {
    const cellValue = row.getValue(columnId);
    if (!cellValue) return false;
    if ((cellValue as string).toString().toLowerCase().includes(filterVal.toString().toLowerCase())) {
      return true;
    }
    return false;
  }, []);

  const table = useReactTable({
    columns,
    data,
    state: {
      globalFilter,
      ...(sorting && { sorting }),
    },
    filterFns: {
      fuzzy: stringFilter,
    },
    globalFilterFn: stringFilter,
    onGlobalFilterChange: setGlobalFilter,
    initialState: {
      pagination: {
        pageSize: initialPageSize ?? 10,
        pageIndex: pageIndex ?? 0,
      },
      sorting,
    },
    onStateChange: (updater) => {
      if (typeof updater !== "function") return;
      const newState = updater(table.getState());
      onPageIndexChange?.(newState.pagination.pageIndex);
    },
    ...(onSortingChange && { onSortingChange }),
    enablePinning: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: paginate ? getPaginationRowModel() : undefined,
    getFilteredRowModel: getFilteredRowModel(),
  });

  try {
    //debug, to be removed
    table.getRowModel();
  } catch (e) {
    console.log(`error table.getRowModel ${JSON.stringify(e)}`);
    throw e;
  }
  try {
    //debug try catch, to be removed
    const tableRows = table.getRowModel().rows;
    return (
      <div className="flex h-full w-full flex-col">
        {!previewView && !dashboardView && headerInfo && (
          <div>{typeof headerInfo === "function" ? headerInfo() : headerInfo}</div>
        )}

        <div className={tableWrapperStyles}>
          {tableRows.length ? (
            <table className={clsx(transpose && "table--flip", "table-auto")}>
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <HeaderRow key={headerGroup.id} headerGroup={headerGroup} />
                ))}
              </thead>
              <tbody className="scrollbar">
                {tableRows.map((row) => (
                  <Row row={row} key={row.id} />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-muted-foreground bg-background flex h-full w-full flex-col items-center justify-center">
              <AlertTriangleIcon className="size-10 text-muted-foreground" />
              <div className="mt-4 text-sm">No Data to Display</div>
            </div>
          )}
        </div>
        {customFooter ? (
          customFooter(table)
        ) : (
          <div className={clsx("flex items-center justify-start pb-1 pl-2")}>
            <FooterSearch globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
            <PaginationControls table={table} />
          </div>
        )}
      </div>
    );
  } catch (e) {
    console.log(`error rendering table ${JSON.stringify(e)}`);
    throw e;
  }
};
