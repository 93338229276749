import { flexRender } from "@tanstack/react-table";
import type { Cell as CellType } from "@tanstack/table-core";

interface CellProps<T, D> {
  cell: CellType<T, D>;
}

// Efficient URL validation using regex
const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

const Cell = <T, D>({ cell }: CellProps<T, D>) => {
  const value = cell.getValue();

  // Handle boolean values
  if (typeof value === "boolean") {
    return (
      <td className="text-content/80 whitespace-nowrap border-x p-2 text-sm font-normal">{value ? "TRUE" : "FALSE"}</td>
    );
  }

  // Handle potential URL values
  if (typeof value === "string" && urlPattern.test(value)) {
    return (
      <td className="text-content/80 whitespace-nowrap border-x p-2 text-sm font-normal">
        <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          {value}
        </a>
      </td>
    );
  }

  // Default rendering
  const content = flexRender(cell.column.columnDef.cell, cell.getContext());
  return <td className="text-content/80 whitespace-nowrap border-x p-2 text-sm font-normal">{content}</td>;
};

export default Cell;
