import type { HeaderGroup as HeaderGroupType } from "@tanstack/table-core";
import Header from "./header";

interface HeaderRowProps<T> {
  headerGroup: HeaderGroupType<T>;
}

const HeaderRow = <T,>({ headerGroup }: HeaderRowProps<T>) => {
  return (
    <tr key={headerGroup.id} className="bg-background border">
      {headerGroup.headers.map((header) => (
        <Header key={header.id} header={header} />
      ))}
    </tr>
  );
};

export default HeaderRow;
